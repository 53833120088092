body {
  margin: 0;
  font-family: "RetroGaming", sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-color: #00001b;
}

@font-face {
  font-family: "RetroGaming";
  src: local("Retro Gaming"), url("./fonts/RetroGaming.ttf") format("truetype");
}

img {
  display: block;
  max-width: 100%;
}

.flex {
  display: flex;
}

.item-center {
  align-items: center;
}

.noselect {
  -webkit-touch-callout: none; /* iOS Safari */
  -webkit-user-select: none; /* Safari */
  -khtml-user-select: none; /* Konqueror HTML */
  -moz-user-select: none; /* Old versions of Firefox */
  -ms-user-select: none; /* Internet Explorer/Edge */
  user-select: none; /* Non-prefixed version, currently
                                  supported by Chrome, Edge, Opera and Firefox */
}
